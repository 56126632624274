@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@400;600&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5; /* Replace with your desired color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div::-webkit-scrollbar {
  width: 0; /* Set width to 0 to make it invisible */
}

* {
  font-family: "Inter", sans-serif;
}

.fade-in {
  animation: top 1s normal forwards ease-in-out;
}

@keyframes top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.MuiSelect-multiple {
  border-color: #00b906;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00b906;
}
