.App {
  text-align: center;
}

.markdown a {
  color: #B9E937;
}

.markdown th {
  text-align: start;
  border: 1px solid #F5F5F5;
  padding: 0.5rem;
}

.markdown table {
  border: 1px solid #F5F5F5;
  border-collapse: collapse;
  border-radius: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown table td {
  border: 1px solid #F5F5F5;
  padding: 0.5rem;
}

.markdown ul {
  list-style-type: disc;
  margin-left: 1rem;
}

.markdown ol {
  list-style-type: decimal;
  margin-left: 1rem;
}
